// let url = process.env.REACT_APP_BASE_URL;
let url ="https://node.gyanbatua.ai";
// let url ="http://172.16.1.266:2055";


const coePartner = `${url}/api/v1/coePartners`;
const Blogs = `${url}/api/v1/blog`;
const media = `${url}/api/v1/media`;
const membership = `${url}/api/v1/membership`;
const landing = `${url}/api/v1/landingUniversity`;
const payment = `${url}/api/v1/payment`;

const ApiConfig = { 
  subcriptionDatalist: `${payment}/subcriptionDatalist`,
  fetchlatestToAddressTransactions: `${payment}/fetchlatestToAddressTransactions`,
  fetchLatestTransactionDetails: `${payment}/fetchLatestTransactionDetails`,
  fetchTrackAddressDetails: `${payment}/fetchTransactionDetails`,
  fetchUniqueAddresses: `${payment}/fetchUniqueAddresses`,
  getWalletDetails: `${payment}/getWalletDetails`,
  getGCBalance: `${payment}/getGCBalance`,
  getUserWalletDetails: `${payment}/getUserWalletDetails`,
  getFilteredTransactions: `${payment}/getFilteredTransactions`, 
  fetchcurrentDateData: `${payment}/fetchcurrentDateData`,
  fetchPerticularMonthInfoData: `${payment}/fetchPerticularMonthInfoData`,
  fetchCurrentDateInfoData: `${payment}/fetchCurrentDateInfoData`,
  fetchDateInfoData: `${payment}/fetchDateInfoData`,
  fetchSkillsInfoData: `${payment}/fetchSkillsInfoData`,
  fetchSubskillsInfoData: `${payment}/fetchSubskillsInfoData`,
  fetchStatusInfoData: `${payment}/fetchStatusInfoData`,
  fetchPlanInfoData: `${payment}/fetchPlanInfoData`,
  fetchSubscriptionData: `${payment}/fetchSubscriptionData`,


  listAllUniversity: `${landing}/listAllUniversity`,

  

  createPlan: `${membership}/createPlan`,
  viewPlan: `${membership}/viewPlan`,
  editPlan: `${membership}/editPlan`,
  deleteplan: `${membership}/deleteplan`,
  listplan: `${membership}/listplan`,

  addBlog: `${Blogs}/addBlog`,
  blogList: `${Blogs}/blogList`,
  viewBlog: `${Blogs}/viewBlog`,
  editBlog: `${Blogs}/editBlog`,
  deleteBlog: `${Blogs}/deleteBlog`,

  addMedia: `${media}/addMedia`,
  listMedia: `${media}/listMedia`,
  viewMedia: `${media}/viewMedia`,
  editMedia: `${media}/editMedia`,
  deleteMedia: `${media}/deleteMedia`,
  listAllMedia: `${media}/listAllMedia`,

  addCoePartner: `${coePartner}/addCoePartner`,
  coePartnerList: `${coePartner}/coePartnerList`,
  viewCoePartner: `${coePartner}/viewCoePartner`,
  editCoePartner: `${coePartner}/editCoePartner`,
  deleteCoePartner: `${coePartner}/deleteCoePartner`,

  userListByCourse: `${url}/api/v1/admin/userListByCourse`,
  userListByDropCourse: `${url}/api/v1/admin/userListByDropCourse`,
  logIn: `${url}/api/v1/admin/logIn`,
  otpVerify: `${url}/api/v1/admin/otpVerify`,
  profile: `${url}/api/v1/admin/profile`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  createUserBadgeOtherArray: `${url}/api/v1/admin/createUserBadgeOtherArray`,
  createUserBadgeArray: `${url}/api/v1/admin/createUserBadgeArray`,
  dashboard: `${url}/api/v1/admin/dashboard`,
  userList: `${url}/api/v1/admin/userList`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  userTransactionList: `${url}/api/v1/admin/userTransactionList`,
  addSubAdmin: `${url}/api/v1/admin/addSubAdmin`,
  subadminList: `${url}/api/v1/admin/subadminList`,
  viewSubAdmin: `${url}/api/v1/admin/viewSubAdmin`,
  updateSubAdmin: `${url}/api/v1/admin/updateSubAdmin`,
  blockUnblockSubAdmin: `${url}/api/v1/admin/blockUnblockSubAdmin`,
  transactionList: `${url}/api/v1/admin/transactionList`,
  getReferralList: `${url}/api/v1/admin/getReferralList`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  getLogs: `${url}/api/v1/admin/getLogs`,
  userBadgeList: `${url}/api/v1/admin/userBadgeList`,
  replyToTicket: `${url}/api/v1/admin/replyToTicket`,
  findAllSubscribe: `${url}/api/v1/user/findAllSubscribe`,
  uploadImage: `${url}/api/v1/user/uploadImage`,

  addPrice: `${url}/api/v1/price/addPrice`,
  priceList: `${url}/api/v1/price/priceList`,
  priceDelete: `${url}/api/v1/price/priceDelete`,
  priceUpdate: `${url}/api/v1/price/priceUpdate`,
  priceView: `${url}/api/v1/price/priceView`,

  addSkills: `${url}/api/v1/skill/addSkills`,
  skillsList: `${url}/api/v1/skill/skillsList`,
  addSubSkills: `${url}/api/v1/skill/addSubSkills`,
  viewSkills: `${url}/api/v1/skill/viewSkills`,
  viewSubSkills: `${url}/api/v1/skill/viewSubSkills`,
  deleteSkills: `${url}/api/v1/skill/deleteSkills`,
  deleteSubSkills: `${url}/api/v1/skill/deleteSubSkills`,
  editSkills: `${url}/api/v1/skill/editSkills`,
  subskillsList: `${url}/api/v1/skill/subskillsList`,
  editSubSkills: `${url}/api/v1/skill/editSubSkills`,

  ticketList: `${url}/api/v1/ticket/ticketList`,
  updateTicketStatus: `${url}/api/v1/ticket/updateTicketStatus`,
  fetchRepliesByTicketId: `${url}/api/v1/ticket/fetchRepliesByTicketId`,

  addAnnouncement: `${url}/api/v1/notification/addAnnouncement`,
  listAnnouncement: `${url}/api/v1/notification/listAnnouncement`,
  viewAnnouncement: `${url}/api/v1/notification/viewAnnouncement`,

  getAllCategory: `${url}/api/v1/course/getAllCategory`,
  createCategory: `${url}/api/v1/course/createCategory`,
  updateCategory: `${url}/api/v1/course/updateCategory`,
  deleteCategory: `${url}/api/v1/course/deleteCategory`,
  createSubCategory: `${url}/api/v1/course/createSubCategory`,
  getAllSubCategory: `${url}/api/v1/course/getAllSubCategory`,
  updateSubCategory: `${url}/api/v1/course/updateSubCategory`,
  deleteSubCategory: `${url}/api/v1/course/deleteSubCategory`,
  createCourse: `${url}/api/v1/course/createCourse`,
  listCourse: `${url}/api/v1/course/listCourse`,
  deleteCourse: `${url}/api/v1/course/deleteCourse`,
  adminRejectSkillDrop: `${url}/api/v1/course/adminRejectSkillDrop`,
  adminApproveSkillDrop: `${url}/api/v1/course/adminApproveSkillDrop`,
  getCourse: `${url}/api/v1/course/getCourse`,
  editCourse: `${url}/api/v1/course/editCourse`,
  fetchDataForCreateCourse: `${url}/api/v1/course/fetchDataForCreateCourse`,
  getAllCoursesBySubCategory: `${url}/api/v1/course/getAllCoursesBySubCategory`,

  createReferalCode: `${url}/api/v1/referalcode/createReferalCode`,
  referalCodeList: `${url}/api/v1/referalcode/referalCodeList`,
  deleteReferalCode: `${url}/api/v1/referalcode/deleteReferalCode`,
  viewReferalCode: `${url}/api/v1/referalcode/viewReferalCode`,
  editReferalCode: `${url}/api/v1/referalcode/editReferalCode`,
};
export default ApiConfig;
